<template>
  <div>
    <Form @submit="onSubmit" :validation-schema="schema" :initial-values="userDataForProfileForm">
      <div class="grid-row">
        <div class="col-12_md-6">
          <div class="form-item">
            <label for="" class="form-label">會員名稱</label>
            <input type="text" class="form-input" readonly :value="userName" />
          </div>
        </div>
        <template v-if="isCompanyAccount">
          <div class="col-12_md-6">
            <div class="form-item">
              <label for="" class="form-label">統一編號</label>
              <input type="text" class="form-input" readonly :value="userData.unNumber" />
            </div>
          </div>
          <div class="col-12_md-6">
            <div class="form-item">
              <label for="" class="form-label">負責人</label>
              <input type="text" class="form-input" readonly :value="userData.headerName" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-12_md-6">
            <div class="form-item">
              <label for="" class="form-label">身分證字號</label>
              <input type="text" class="form-input" readonly :value="userData.iDNumber" />
            </div>
          </div>
        </template>
      </div>

      <hr class="form-hr" />

      <div class="form-title">主要聯絡人</div>
      <div class="grid-row">
        <div class="col-12_md-6">
          <FormInput field-name="contactName" field-label="姓名" />
        </div>
        <div class="col-12_md-6">
          <FormInput field-name="contactEmail" field-label="Email" field-type="email" />
        </div>
        <div class="col-12_md-6">
          <FormInput field-name="contactTel" field-label="電話" field-type="tel" />
        </div>
        <div class="col-12_md-6">
          <FormInput field-name="contactMobile" field-label="手機" />
        </div>
        <div class="col-12_md-6">
          <FormInput field-name="contactFax" field-label="傳真" :is-required="false" />
        </div>
      </div>
      <div class="grid-row">
        <div class="col-12">
          <FormInput field-label="聯絡地址" field-name="address" />
        </div>
      </div>

      <hr class="form-hr" />

      <div class="form-title">其他聯絡人</div>
      <div class="grid-row">
        <div class="col-12_md-6">
          <FormInput field-name="otherName" field-label="姓名" :is-required="false" />
        </div>
        <div class="col-12_md-6">
          <FormInput
            field-name="otherEmail"
            field-label="Email"
            field-type="email"
            :is-required="false"
          />
        </div>
        <div class="col-12_md-6">
          <FormInput
            field-name="otherTel"
            field-label="電話"
            field-type="tel"
            :is-required="false"
          />
        </div>
        <div class="col-12_md-6">
          <FormInput field-name="otherMobile" field-label="手機" :is-required="false" />
        </div>
      </div>

      <div class="btn-group">
        <RouterLink to="/user" class="btn btn-secondary btn-md">返回</RouterLink>
        <button type="submit" class="btn btn-primary btn-md">儲存</button>
      </div>
    </Form>
  </div>
</template>
<script>
import { Form } from 'vee-validate';
import { updateProfile as schema } from '@/utils/schema';
import FormInput from '@/components/Input/FormInput.vue';
import { mapActions, mapState } from 'pinia';
import { useUserData } from '@/store/user';

export default {
  components: {
    Form,
    FormInput,
  },
  data() {
    return {
      schema,
    };
  },
  methods: {
    async onSubmit(formData) {
      await this.updateUserData(formData);
      this.$router.push('/user');
    },
    ...mapActions(useUserData, ['updateUserData']),
  },
  computed: {
    ...mapState(useUserData, ['userData', 'userName', 'isCompanyAccount', 'userDataForProfileForm']),
  },
};
</script>
